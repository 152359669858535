<template>
  <div class="tag-browser">
    <div class="tag-browser-button" @click="tagBrowserButtonClicked()">
      <span class="name">
        #{{ isBrowsingTagCardList ? $route.params.slug : 'Теги' }}
      </span>
      <img src="../../assets/icons/tag-arrow.svg" alt="" v-if="!isBrowsingTagCardList">
      <img src="../../assets/icons/tag-cancel.svg" alt="" v-if="isBrowsingTagCardList">
    </div>
    <div class="browser-popup" v-if="isShown">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input type="search" placeholder="Введите тег" v-model="tagInput">
      <div class="tag-list" v-if="tags.length > 0">
        <router-link v-for="tag in tags" :key="tag" :to="{ name: 'tag-card-list', params: { slug: tag } }" >
          #{{ tag }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagBrowser',

  data() {
    return {
      isShown: false,
      tagInput: '',
      tags: [],
      timeout: null,
    }
  },

  watch: {
    $route: 'routeChanged',
    tagInput: 'tagInputChanged',
  },

  methods: {
    tagBrowserButtonClicked() {
      if(this.isBrowsingTagCardList) {
        this.$router.push({ name: 'home' })
        return
      }

      this.isShown = !this.isShown
      this.tagInput = ''
      this.tags = this.shortTopTags
    },

    routeChanged() {
      this.isShown = false
    },

    tagInputChanged() {
      if(this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }

      if(this.filteredTagInput.length < 1) {
        this.tags = this.shortTopTags
        return
      }

      this.timeout = setTimeout(() => {
        this.$root.makeAPIRequest('tags', 'search', {
          name: this.filteredTagInput,
          limit: 20,
        })
          .then(response => {
            if(response.data.error) {
              console.error(`Ошибка поиска по тегам: ${response.data.message}`)
              this.tags = []
              this.timeout = null
              return
            }

            this.tags = response.data.result
            this.timeout = null
          })
      }, 200)
    },
  },

  computed: {
    filteredTagInput() {
      return this.tagInput.trim()
    },

    isBrowsingTagCardList() {
      return this.$route.name === 'tag-card-list'
    },

    shortTopTags() {
      const amount = 10

      if(this.$root.topTags === null) {
        return this.$root.topTags
      }

      if(this.$root.topTags.length < amount) {
        return this.$root.topTags
      }

      const shortenedTopTags = this.$root.topTags.slice(0, amount)
      return shortenedTopTags
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .tag-browser {
    display: flex;
    align-items: center;
    position: relative;

    @media @tablet-m {
      width: 250px;
      justify-content: center;
    }
  }

  .tag-browser-button {
    font-weight: bold;
    font-size: 13pt;
    cursor: pointer;
    overflow: hidden;
    padding: 0.25em 0;
    border-bottom: 2px solid @color-accent;
    box-sizing: border-box;

    display: flex;

    img {
      height: 10px;
      align-self: center;
      transform: translateY(1.5px);
      margin-left: 0.3em;
    }
  }

  .name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .browser-popup {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: @z-index-tag-browser-popup;
    padding: @icon-margin;
    box-sizing: border-box;
    overflow: hidden;
    background-color: @color-block-background;
    width: 250px;

    input {
      .input();
      appearance: none;
      width: 100%;
      margin: 0;
    }
  }

  .tag-list {
    margin-top: 0.5em;
    overflow-y: scroll;
    max-height: 250px;

    a {
      display: block;
      padding: 0.25em 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>

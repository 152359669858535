<template>
  <div class="regp">
    <div class="button close" @click="closeClicked">
      <img src="../assets/icons/close.svg">
    </div>
    <span class="promo-text">{{ promoText }}</span>
    <div class="button register" @click="registerClicked">Зарегистрироваться</div>
  </div>
</template>

<script>
export default {
  name: 'RegisterPopup',
  data() {
    return {
      promoCaptions: [
        'Вы хотите создавать умный контент вместе с нами? Пишите статьи в своём канале.',
        'Вам есть что рассказать! Ведите свой канал в Helperia.',
        'Присоединяйтесь к сообществу умных и интересных авторов! Делитесь своими знаниями и мнением!',
      ],
    }
  },

  computed: {
    promoText() {
      if(this.$route.name === 'article-content-reader') {
        return 'Знаете больше, чем автор статьи? Дополните её.'
      }

      const randomText = this.promoCaptions[Math.floor(Math.random() * this.promoCaptions.length)]
      return randomText
    },
  },

  methods: {
    closeClicked() {
      this.$root.isShowingRegisterPopup = false
      this.$cookies.set('helperia-next-register-popup-dismissed', 'yes', '7d')
    },

    registerClicked() {
      this.$root.isShowingRegisterPopup = false
      this.$router.push({ name: 'register' })
      this.$cookies.set('helperia-next-register-popup-dismissed', 'yes', '1d')
    },
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  .regp {
    position: fixed;
    z-index: @z-index-register-popup;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: @color-accent;
    color: @color-block-background;
    padding: @s-margin;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close {
    align-self: flex-end;
  }

  .promo-text {
    text-align: center;
    font-family: @font-set-header;
    font-size: 18pt;
  }

  .register {
    font-weight: bold;
    padding: 0.5em 1em;
    margin-top: 0.5em;
    background-color: @color-block-background;
    color: @color-accent;
  }

  img {
    filter: invert(100%);
  }
</style>

<template>
  <div class="top-tags" v-show="$root.topTags != null">
    <span class="title">Топ тегов</span>
    <div class="tag-list">
      <div class="tag" v-for="tag in $root.topTags" :key="tag">
        <router-link :to="{ name: 'tag-card-list', params: { slug: tag } }">
          #{{ tag }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopTags',
  created() {
    this.$root.makeAPIRequest('tags', 'get_top')
      .then(response => {
        if(response.data.error) {
          this.tags = null
          console.warn(`Невозможно загрузить теги: ${response.data.message}`)
          return
        }

        this.$root.topTags = response.data.result.tags
      })
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';
  .top-tags {
    display: flex;
    flex-direction: column;
    padding: 0 @xxl-margin;
    box-sizing: border-box;

    @media @tablet-m {
      display: none;
    }
  }

  .title {
    font-family: @font-set-header;
    font-size: @font-size-header;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  .tag-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.4em;
  }

  .tag {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }

  a {
    color: @color-text;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<template>
  <footer>
    <div class="white-bar">
      <div class="wrap">
        <Logo/>
        <FormatNav/>
      </div>
    </div>
    <TopTags/>
    <div class="description">
      <span class="copyright">© 2020, Helperia.ru</span>
      <router-link :to="{ name: 'changelog' }">v{{ version }}</router-link>
      <SocialLinks class="greyscale"/>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Shared/Logo'
import FormatNav from '@/components/Shared/FormatNav'
import SocialLinks from '@/components/Shared/SocialLinks'
import TopTags from '@/components/Footer/TopTags'

export default {
  name: 'Footer',
  components: { TopTags, SocialLinks, FormatNav, Logo },

  computed: {
    version: {
      get() {
        return '0.1'
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;

    @media @mobile-l {
      margin-top: 2em;
    }
  }

  .white-bar {
    .header-white-bar();
    z-index: @z-index-footer-white-bar;

    @media @tablet-s {
      display: none;
    }
  }

  .top-tags {
    width: var(--content-width);
    margin: 2em 0;
  }

  .description {
    width: var(--content-width);
    display: flex;
    justify-content: space-between;
    padding: @s-margin;
    border-top: 0.6px solid @color-text-light;
    box-sizing: border-box;
    color: @color-text-light;

    a {
      color: @color-text-light;
    }

    @media @tablet-s {
      padding: 1em;
    }

    @media @mobile-l {
      flex-direction: column;
      align-items: center;

      &>*+* {
        margin-top: 0.75em;
      }
    }
  }
</style>

<template>
  <nav class="breadcrumbs">
    <router-link
      v-for="breadcrumb in getBreadcrumbs()"
      :key="breadcrumb.key"
      :to="breadcrumb.route"
    >
      {{ breadcrumb.name }}
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  methods: {
    getBreadcrumbs() {
      const breadcrumbs = [
        {
          key: 'home',
          name: 'Главная',
          route: {
            name: 'home',
          },
        },
      ]

      if(this.$route.name === 'change-username') {
        const changeUsername = {
          key: 'change-username',
          name: (this.$root.auth && this.$root.auth.username !== null) ? 'Смена имени' : 'Указание имени',
          route: {
            name: 'change-username',
          },
        }
        breadcrumbs.push(changeUsername)
        return breadcrumbs
      }

      if(this.$route.name === 'change-password') {
        const changePassword = {
          key: 'change-password',
          name: 'Смена пароля',
          route: {
            name: 'change-password',
          },
        }
        breadcrumbs.push(changePassword)
        return breadcrumbs
      }

      if(this.$route.name === 'changelog') {
        const changelog = {
          key: 'changelog',
          name: 'Список изменений',
          route: {
            name: 'changelog',
          },
        }
        breadcrumbs.push(changelog)
        return breadcrumbs
      }

      if(this.$route.name === 'register') {
        const register = {
          key: 'register',
          name: 'Создание канала',
          route: {
            name: 'register',
          },
        }
        breadcrumbs.push(register)
        return breadcrumbs
      }

      if(this.$route.name === 'user-profile') {
        const users = {
          key: 'users',
          name: 'Эксперты',
          route: {
            name: 'users'
          }
        }
        breadcrumbs.push(users)

        const user = {
          key: 'user-profile',
          name: this.$route.params.slug,
          route: {
            name: 'user-profile',
            params: {
              slug: this.$route.params.slug,
            }
          }
        }
        breadcrumbs.push(user)

        return breadcrumbs
      }

      const contentTypes = [
        { type: 'article', name: 'Статьи' },
        { type: 'discussion', name: 'Дискуссии' },
        { type: 'event', name: 'Публичные события' },
        { type: 'question', name: 'Вопросы' },
      ]
      const isReading = {}

      for(const contentType of contentTypes) {
        const type = contentType.type
        const types = `${type}s`
        isReading[types] = this.$route.name === types
        isReading[type] = this.$route.name === `${type}-content-reader`

        if(isReading[types] || isReading[type]) {
          const category = {
            key: types,
            name: contentType.name,
            route: {
              name: types,
            },
          }
          breadcrumbs.push(category)
        }

        if(isReading[type]) {
          const material = {
            key: 'content',
            name: this.$root.contentName ? this.$root.contentName : 'Загрузка…',
            route: {
              name: this.$route.name,
              params: this.$route.params,
            },
          }
          breadcrumbs.push(material)
        }
      }

      if(breadcrumbs.length < 2) {
        return []
      }

      return breadcrumbs
    }
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  nav {
    flex-grow: 1;
    overflow: hidden;

    display: flex;
  }

  a {
    white-space: nowrap;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &+&::before {
      margin-left: 0.25em;
      content: '»';
      pointer-events: none;
      color: @color-text;
    }

    &:last-child {
      pointer-events: none;
      color: @color-text;
      flex-shrink: 1;
    }
  }
</style>

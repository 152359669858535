<template>
  <div class="search-form">
    <!--suppress HtmlFormInputWithoutLabel -->
    <input type="search" v-model="searchText" @keyup.enter="querySearch" ref="searchField">
    <div class="button search" @click="querySearch">
      <img src="../../assets/icons/search.svg" alt="Поиск">
      <span class="search-text">Поиск</span>
    </div>
    <div class="suggestions" v-if="searchTextEntered">
      <span class="type-tip" v-if="searchTextTooShort">Уточните ваш запрос</span>
      <span class="type-tip" v-if="noResults">Предложений нет</span>
      <span class="type-tip" v-if="noResults">Нажмите «Поиск», чтобы увидеть подробные результаты</span>
      <!--suppress CheckTagEmptyBody -->
      <router-link
        v-for="suggestion in $root.search.suggestions"
        :key="`${suggestion.type}/${suggestion.slug}`"
        :to="{ name: `${suggestion.type}-content-reader`, params: { slug: suggestion.slug } }"
      >{{ suggestion.title }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchForm',

  data() {
    return {
      timeout: null,
      cancelTokenSource: null,
      noResults: false,
    }
  },

  methods: {
    querySearch() {
      const trimmedText = this.searchTextTrimmed
      if(trimmedText === '') {
        return
      }

      this.$refs.searchField.blur()
      this.$router.push({ name: 'search', query: { query: trimmedText } })
    },

    updateSearchSuggestions() {
      this.timeout = null
      this.$root.makeAPIRequest('search', 'get_suggestions', {
        query: this.searchTextTrimmed,
      }, this.setCancelToken)
        .then(response => {
          if(response.data.error) {
            this.$root.search.suggestions = []
            this.noResults = true
            console.warn(`Невозможно загрузить предложения поиска: ${response.data.message}`)
            return
          }

          this.$root.search.suggestions = response.data.result
          this.noResults = this.$root.search.suggestions.length < 1
        })
    },

    maybeUpdateSearchSuggestions() {
      if(this.timeout !== null) {
        clearTimeout(this.timeout)
        this.timeout = null
      }

      if(this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }

      this.timeout = setTimeout(this.updateSearchSuggestions, 200)
    },

    setCancelToken(cancelToken) {
      this.cancelTokenSource = cancelToken
    },
  },

  computed: {
    searchText: {
      get() {
        return this.$root.search.text
      },

      set(newValue) {
        this.$root.search.text = newValue
        if(this.searchTextTooShort) {
          this.$root.search.suggestions = []
          this.noResults = false
          return
        }
        this.maybeUpdateSearchSuggestions()
      },
    },

    searchTextTrimmed() {
      const trimmedText = this.searchText.trim()
      return trimmedText
    },

    searchTextEntered() {
      const trimmedText = this.searchTextTrimmed
      return trimmedText.length > 0
    },

    searchTextTooShort() {
      const trimmedText = this.searchTextTrimmed
      return trimmedText.length < 4
    },
  },
}
</script>

<style lang="less" scoped>
  @import '../../shared.less';

  @search-form-height: 40px;
  @search-field-border-color: lighten(@color-border, 15%);

  .search-form {
    display: flex;
    flex-grow: 1;
    margin-left: 50px;
    height: @search-form-height;
    position: relative;
    --search-button-width: 150px;

    @media @tablet-s {
      margin: @s-margin;
      --search-button-width: 36px;
    }
  }

  input {
    flex-grow: 1;
    border: 1px solid @search-field-border-color;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
    padding: 0 0.75em;
    margin: 0;
    min-width: 0;

    &:focus {
      outline: none;
    }

    @media @tablet-s {
      background-color: @color-block-background;
      padding: 0 0.25em;
    }
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--search-button-width);
    background-color: @color-accent;
    color: @color-block-background;
    flex-shrink: 0;

    img {
      margin-right: @icon-margin;
      filter: invert(100%);

      @media @tablet-s {
        margin-right: unset;
      }
    }
  }

  .search-text {
    @media @tablet-s {
      display: none;
    }
  }

  .suggestions {
    position: absolute;
    top: @search-form-height - 1px;
    left: 0;
    background-color: @color-block-background;
    border: 1px solid @search-field-border-color;
    //display: flex;
    display: none;
    flex-direction: column;
    width: calc(100% - var(--search-button-width));
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;

    input:focus+*+&,
    &:focus,
    &:active {
      display: flex;
    }

    span {
      align-self: center;
      padding: 0.4em 1.4em;
      opacity: 0.5;
      text-align: center;
    }

    a {
      padding: 0.4em 1.4em;
      color: @color-text;

      &:hover {
        background-color: fade(@color-accent, 30%);
      }

      @media @tablet-s {
        padding: 0.4em 0.9em;
      }
    }

    @media @tablet-s {
      width: 100%;
      max-height: 300px;
    }
  }
</style>

<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
    <transition name="register-move">
      <RegisterPopup v-if="$root.isShowingRegisterPopup"/>
    </transition>
    <Overlay v-if="$root['overlay']"/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Overlay from '@/components/Overlay'
import RegisterPopup from '@/components/RegisterPopup'
export default {
  components: { RegisterPopup, Overlay, Footer, Header },

  created() {
    this.setSessionFromCookies()
    if (this.$root.auth) {
      this.verifySession()
    }
    this.prepareRegisterPopup()
  },

  methods: {
    setSessionFromCookies() {
      const token = this.$cookies.get('helperia-next-token')
      if(token === null) {
        return
      }

      const username = this.$cookies.get('helperia-next-username')
      this.$root.auth = {
        token,
        username,
      }
    },

    verifySession() {
      console.log('Hello!')
      this.$root.makeAPIRequest('auth', 'verify_token')
        .then((response) => {
          if(response.data.error) {
            console.warn(`Ошибка проверки токена: ${response.data.message}`)
            return
          }

          if(response.data.result.valid) {
            this.$root.setSession(response.data.result.auth)
            return
          }

          this.$root.dropSession()
        })
    },

    prepareRegisterPopup() {
      if(this.$root.auth) {
        this.dismissRegisterPopup()
        return
      }

      const popupDismissed = this.$cookies.get('helperia-next-register-popup-dismissed')
      if(popupDismissed) {
        return
      }

      setTimeout(this.showRegisterPopup, 20000)
    },

    showRegisterPopup() {
      if(this.$root.auth) {
        this.dismissRegisterPopup()
        return
      }

      this.$root.isShowingRegisterPopup = true
    },

    dismissRegisterPopup() {
      this.$root.isShowingRegisterPopup = false
      this.$cookies.set('helperia-next-register-popup-dismissed', 'yes', '7d')
    },
  },
}
</script>

<style lang="less">
  @import './shared.less';
  @import './assets/fonts/roboto-slab/font.less';

  :root {
    --content-width: @content-width;

    --color-type-article: 18, 80%, 60%;
    --color-type-discussion: 130, 35%, 55%;
    --color-type-question: 353, 75%, 70%;
    --color-type-event: 196, 65%, 60%;
    --color-type-user: 240, 70%, 65%;

    @media @tablet-m {
      --content-width: calc(100% - @s-margin*2);
    }
  }

  html {
    min-height: 100%;
    overflow-y: scroll;
  }

  body {
    margin: 0;
    min-height: 100vh;
    min-width: 320px;
    background-color: @color-page-background;
    font-family: @font-set-main;
    font-size: @font-size-normal;
    color: @color-text;
    position: relative;
  }

  #app {
    min-height: inherit;
    display: flex;
    flex-direction: column;
  }

  main {
    flex-grow: 1;
    flex-shrink: 0;
  }

  a {
    color: @color-accent;
    text-decoration: none;
  }

  h1 {
    color: @color-text;
    font-weight: 700;
    margin: 0 0 0.5em;
    font-size: 24pt;

    @media @tablet-s {
      font-size: 18pt;
    }
  }

  .button {
    cursor: pointer;
  }

  :focus {
    outline: unset;
  }

  input {
    background-color: transparent;
    font-size: @font-size-normal;
  }

  .emphasis {
    font-style: italic;
  }

  .strong {
    font-weight: bold;
  }

  .register-move-enter,
  .register-move-leave-to {
    transform: translateY(100%);
  }

  .register-move-leave,
  .register-move-enter-to {
    transform: translateY(0%);
  }

  .register-move-enter-active,
  .register-move-leave-active {
    transition: transform 0.4s ease-out;
  }
</style>

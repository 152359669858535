<template>
  <div class="mobile-menu" :class="{ active: $root['isMobileMenuActive'] }">
    <FormatNav class="is-mobile"/>
    <UserActions class="is-mobile"/>
    <SocialLinks/>
  </div>
</template>

<script>
import FormatNav from '@/components/Shared/FormatNav'
import UserActions from '@/components/Header/UserActions'
import SocialLinks from '@/components/Shared/SocialLinks'

export default {
  name: 'MobileMenu',
  components: { SocialLinks, UserActions, FormatNav },
  watch: {
    '$route'() {
      this.$root.isMobileMenuActive = false
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../shared.less';

  .mobile-menu {
    @border: 1px solid @color-border;

    width: 320px;
    background-color: @color-block-background;
    box-sizing: border-box;
    border-left: @border;
    border-bottom: @border;
    position: absolute;
    top: var(--bar-height);
    right: 0;
    z-index: @z-index-mobile-menu;
    transform: translateY(-100%);
    pointer-events: none;
    transition: transform 0.3s @transition-ease-out-quart;

    display: flex;
    flex-direction: column;

    &.active {
      transform: translateY(0%);
      pointer-events: auto;
    }
  }

  .user-actions {
    @border: 1px solid @color-border;
    @margin: 5px;

    border-top: @border;
    margin-top: @margin;
    padding-top: @margin;
  }

  .scl-links {
    align-self: center;
    margin: 8px 0;
  }
</style>

<template>
  <header>
    <div class="desktop-nav-white-bar">
      <div class="nav-social-user">
        <Breadcrumbs/>
        <SocialLinks/>
        <UserActions/>
      </div>
      <div class="white-bar">
        <div class="wrap">
          <Logo/>
          <SearchForm/>
        </div>
      </div>
    </div>
    <div class="mobile-nav-white-bar">
      <div class="mobile-white-bar">
        <Logo class="is-mobile"/>
        <MobileNavToggler/>
      </div>
      <MobileMenu/>
      <div class="dark-overlay" :class="{ active: $root['isMobileMenuActive'] }" @click="$root['isMobileMenuActive'] = false"/>
      <SearchForm class="is-mobile"/>
    </div>
    <div class="type-nav">
      <FormatNav/>
      <TagBrowser/>
    </div>
  </header>
</template>

<script>
import Breadcrumbs from '@/components/Header/Breadcrumbs'
import SocialLinks from '@/components/Shared/SocialLinks'
import Logo from '@/components/Shared/Logo'
import FormatNav from '@/components/Shared/FormatNav'
import TagBrowser from '@/components/Header/TagBrowser'
import SearchForm from '@/components/Header/SearchForm'
import MobileNavToggler from '@/components/Header/MobileNavToggler'
import MobileMenu from '@/components/Header/MobileMenu'
import UserActions from '@/components/Header/UserActions'

export default {
  name: 'Header',
  components: { UserActions, MobileMenu, MobileNavToggler, SearchForm, TagBrowser, FormatNav, Logo, SocialLinks, Breadcrumbs },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .desktop-nav-white-bar {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media @tablet-s {
      display: none;
    }
  }

  nav {
    margin-right: 2em;
  }

  .nav-social-user {
    display: flex;
    padding: 1em 0;
    width: var(--content-width);
    align-self: center;
  }

  .scl-links {
    flex-shrink: 0;
  }

  .user-actions {
    flex-shrink: 0;
  }

  .white-bar {
    .header-white-bar();
    z-index: @z-index-header-white-bar;
  }

  .mobile-nav-white-bar {
    display: none;
    flex-direction: column;
    width: 100%;
    position: relative;
    --bar-height: 60px;
    z-index: @z-index-mobile-nav-white-bar;

    @media @tablet-s {
      display: flex;
    }
  }

  .mobile-white-bar {
    background-color: @color-block-background;
    display: flex;
    justify-content: space-between;
    z-index: @z-index-header-white-bar;
  }

  .mobile-nav-toggler {
    margin-right: 4px;
  }

  .dark-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: @z-index-dark-overlay;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s @transition-ease-out-quart;

    &.active {
      opacity: 0.5;
      pointer-events: auto;
    }
  }

  .type-nav {
    display: flex;
    justify-content: space-between;
    width: var(--content-width);
    padding: 2em 0;

    @media @tablet-m {
      flex-direction: column;
      align-items: center;

      .tag-browser {
        margin-top: @m-margin;
      }
    }

    @media @tablet-s {
      display: none;

      padding: 1em 0;

      .tag-browser {
        margin-top: unset;
      }

      .format-nav {
        margin-bottom: 0.5em;
      }
    }
  }

  .read-header {
    margin: 0 2em;
    font-size: 18pt;
    font-weight: bold;
    font-family: @font-set-header;
  }
</style>

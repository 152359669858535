import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueCookies from 'vue-cookies'
import axios from 'axios'

Vue.config.productionTip = false

const isDebug = process.env.NODE_ENV !== 'production'
const baseURI = 'https://helperia.ru'
const globalParams = {
  isDebug,
  baseURI,
  developerBaseURI: isDebug ? baseURI : '',
  canonicalBaseURI: baseURI,
  apiEndpoint: `${baseURI}/api/`,
  cookieDomain: isDebug ? 'localhost' : '.helperia.ru',
  websiteName: 'Helperia',
  defaultDescription: null,
}

Vue.use(VueYandexMetrika, {
  id: 34759065,
  router: router,
  env: process.env.NODE_ENV,
})

Vue.use(VueCookies)
const cookiesAreHTTPS = !isDebug
Vue.$cookies.config('100d', '/', globalParams.cookieDomain, cookiesAreHTTPS)

// noinspection JSUnusedGlobalSymbols
new Vue({
  router,

  created() {
    router.afterEach((to) => {
      this.updateAllMeta(to)
    })
  },

  data: {
    globalParams,
    search: {
      text: '',
      suggestions: [],
    },
    auth: null,
    overlay: null,
    isMobileMenuActive: false,
    contentName: null,
    attributerAttrs: {
      isShown: false,
      data: {},
      schema: [],
      onSave: null,
      onRemove: null,
      removeSubstring: '',
    },
    topTags: null,
    isShowingRegisterPopup: false,

    contentTypes: {
      article: {
        displayName: 'Статья',
        letter: 'a',
      },
      discussion: {
        displayName: 'Дискуссия',
        letter: 'd',
      },
      question: {
        displayName: 'Вопрос',
        letter: 'q',
      },
      event: {
        displayName: 'Публичное событие',
        letter: 'e',
      },
      user: {
        displayName: 'Эксперт',
        letter: 'u',
      },
    },

    monthNames: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  },

  methods: {
    makeAPIRequest(entity, action, params, setCancelToken = null) {
      const payload = {
        entity,
        action,
        ...params,
      }

      if(this.auth) {
        payload.token = this.auth.token
      }

      if(this.globalParams.isDebug) {
        console.debug(payload)
      }

      let config = {}
      if(setCancelToken) {
        const cancelToken = axios.CancelToken
        const cancelTokenSource = cancelToken.source()
        setCancelToken(cancelTokenSource)
        config = { cancelToken: cancelTokenSource.token }
      }

      const promise = axios.post(this.globalParams.apiEndpoint, payload, config)
      promise.catch((reason) => {
        console.error(reason)
        // alert('Невозможно загрузить данные. Проверьте соединение с интернет.')
      })
      return promise
    },

    setSelectorAttribute(selector, attribute, value, creatorTagName, creatorAttributeName, creatorAttributeValue) {
      let elements = document.querySelectorAll(selector)

      if(!value || elements.length > 1) {
        elements.forEach(element => element.parentNode.removeChild(element))

        if(!value) {
          return
        }

        elements = document.querySelectorAll(selector)
      }

      let element
      if(elements.length < 1) {
        element = document.createElement(creatorTagName)
        if(creatorAttributeName) {
          element.setAttribute(creatorAttributeName, creatorAttributeValue)
        }
        document.head.appendChild(element)
      } else {
        element = elements[0]
      }

      element.setAttribute(attribute, value)
    },

    setMeta(type, name, content) {
      this.setSelectorAttribute(`meta[${type}="${name}"]`, 'content', content, 'meta', type, name)
    },

    setLink(rel, href) {
      this.setSelectorAttribute(`link[rel="${rel}"]`, 'href', href, 'link', 'rel', rel)
    },

    setTitle(title) {
      let documentTitle
      if(title) {
        documentTitle = `${title} - ${this.globalParams.websiteName}`
      } else {
        documentTitle = this.globalParams.websiteName
      }

      document.title = documentTitle
      this.setMeta('property', 'og:title', documentTitle)
    },

    setDescription(description) {
      let guaranteedDescription
      if(description) {
        guaranteedDescription = description
      } else {
        guaranteedDescription = this.globalParams.defaultDescription
      }
      this.setMeta('name', 'description', guaranteedDescription)
      this.setMeta('property', 'og:description', guaranteedDescription)
    },

    setCanonical(canonical) {
      const fullCanonicalURL = canonical ? `${this.globalParams.baseURI}${canonical}` : null
      this.setLink('canonical', fullCanonicalURL)
      this.setMeta('property', 'og:url', fullCanonicalURL)
    },

    setKeywords(keywords) {
      let keywordsString
      if(keywords && keywords.length > 0) {
        keywordsString = keywords.join(', ')
      } else {
        keywordsString = null
      }
      this.setMeta('name', 'keywords', keywordsString)
    },

    updateAllMeta(route) {
      this.setTitle(route.meta.title)
      this.setDescription(route.meta.description)
      this.setCanonical(route.meta.canonical)
      this.setMeta('property', 'og:image', null)
      this.setLink('author', null)
      this.setKeywords(null)
    },

    setSession(auth) {
      this.$cookies.set('helperia-next-token', auth.token)
      this.$cookies.set('helperia-next-username', (auth.username === null) ? '' : auth.username)
      this.$root.auth = auth
      this.$root.isShowingRegisterPopup = false
    },

    dropSession() {
      this.$cookies.remove('helperia-next-token')
      this.$cookies.remove('helperia-next-username')
      this.$root.auth = null
    },

    isDark(color) {
      // В соответствии с ITU-R BT.709
      const luminanceMultiplier = {
        red: 0.2126,
        green: 0.7152,
        blue: 0.0722,
      }
      const cardChannelLuminance = {
        red: luminanceMultiplier.red * color.red,
        green: luminanceMultiplier.green * color.green,
        blue: luminanceMultiplier.blue * color.blue,
      }
      const luminance = cardChannelLuminance.red + cardChannelLuminance.green + cardChannelLuminance.blue
      const isColorDark = luminance < 0.5
      return isColorDark
    },
  },

  render: h => h(App),
}).$mount('#app')

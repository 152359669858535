import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      meta: {
        title: 'Обмен опытом и знаниями',
        canonical: '/',
        description: 'Широкий обмен опытом, знаниями, навыками, советами, идеями и умениями в интернете. Мы предлагаем удобную площадку для развития и общения',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      props: {
        type: 'user',
      },
      meta: {
        title: 'Каналы',
        canonical: '/users',
      },
    },
    {
      path: '/articles',
      name: 'articles',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      props: {
        type: 'article',
      },
      meta: {
        title: 'Статьи',
        canonical: '/articles',
      },
    },
    {
      path: '/articles/new',
      name: 'new-article',
      component: () => import(/* webpackChunkName: "content-editor" */ './views/ContentEditor.vue'),
      props: {
        type: 'article',
        isNewContent: true,
      },
      meta: {
        title: 'Создание статьи',
        canonical: '/articles/new',
      },
    },
    {
      path: '/discussions',
      name: 'discussions',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      props: {
        type: 'discussion',
      },
      meta: {
        title: 'Дискуссии',
        canonical: '/discussions',
      },
    },
    {
      path: '/discussions/new',
      name: 'new-discussion',
      component: () => import(/* webpackChunkName: "content-editor" */ './views/ContentEditor.vue'),
      props: {
        type: 'discussion',
        isNewContent: true,
      },
      meta: {
        title: 'Создание дискуссии',
        canonical: '/discussions/new',
      },
    },
    {
      path: '/events',
      name: 'events',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      props: {
        type: 'event',
      },
      meta: {
        title: 'События',
        canonical: '/events',
      }
    },
    {
      path: '/events/new',
      name: 'new-event',
      component: () => import(/* webpackChunkName: "content-editor" */ './views/ContentEditor.vue'),
      props: {
        type: 'event',
        isNewContent: true,
      },
      meta: {
        title: 'Создание события',
        canonical: '/events/new',
      },
    },
    {
      path: '/questions',
      name: 'questions',
      component: () => import(/* webpackChunkName: "card-list" */ './views/CardList.vue'),
      props: {
        type: 'question',
      },
      meta: {
        title: 'Вопросы',
        canonical: '/questions',
      },
    },
    {
      path: '/questions/new',
      name: 'new-question',
      component: () => import(/* webpackChunkName: "content-editor" */ './views/ContentEditor.vue'),
      props: {
        type: 'question',
        isNewContent: true,
      },
      meta: {
        title: 'Создание вопроса',
        canonical: '/questions/new',
      },
    },
    {
      path: '/a/:slug',
      name: 'article-content-reader',
      component: () => import(/* webpackChunkName: "content-reader" */ './views/ContentReader.vue'),
      props: (route) => ({
        type: 'article',
        slug: route.params.slug,
      }),
    },
    {
      path: '/a/:slug/edit',
      name: 'edit-article',
      component: () => import(/* webpackChunkName: "content-editor" */ './views/ContentEditor.vue'),
      props: route => ({
        type: 'article',
        isNewContent: false,
        oslug: route.params.slug,
      }),
      meta: {
        title: 'Редактирование статьи',
      },
    },
    {
      path: '/d/:slug',
      name: 'discussion-content-reader',
      component: () => import(/* webpackChunkName: "content-reader" */ './views/ContentReader.vue'),
      props: (route) => ({
        type: 'discussion',
        slug: route.params.slug,
      }),
    },
    {
      path: '/e/:slug',
      name: 'event-content-reader',
      component: () => import(/* webpackChunkName: "content-reader" */ './views/ContentReader.vue'),
      props: (route) => ({
        type: 'event',
        slug: route.params.slug,
      }),
    },
    {
      path: '/q/:slug',
      name: 'question-content-reader',
      component: () => import(/* webpackChunkName: "content-reader" */ './views/ContentReader.vue'),
      props: (route) => ({
        type: 'question',
        slug: route.params.slug,
      }),
    },
    {
      path: '/u/:slug',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "user-profile" */ './views/UserProfile.vue'),
      props: (route) => ({
        slug: route.params.slug,
      }),
    },
    {
      path: '/edit-profile',
      name: 'edit-profile',
      component: () => import(/* webpackChunkname: "edit-profile" */ './views/EditProfile.vue'),
      meta: {
        title: 'Редактирование профиля',
      },
    },
    {
      path: '/edit-profile/change-password',
      name: 'change-password',
      component: () => import(/* webpackChunkName: "edit-profile" */ './views/ChangePassword.vue'),
      meta: {
        title: 'Смена пароля',
      },
    },
    {
      path: '/edit-profile/change-username',
      name: 'change-username',
      component: () => import(/* webpackChunkName: "edit-profile" */ './views/ChangeUsername.vue'),
      meta: {
        title: 'Смена имени пользователя',
      },
    },
    {
      path: '/tags/:slug',
      name: 'tag-card-list',
      component: () => import(/* webpackChunkName: "card-list" */ './views/TagCardList.vue'),
      props: route => ({
        slug: route.params.slug
      }),
    },
    {
      path: '/search',
      name: 'search',
      component: () => import(/* webpackChunkName: "card-list" */ './views/Search.vue'),
    },
    {
      path: '/p',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      children: [
        {
          path: 'about',
          name: 'about-about',
          component: () => import(/* webpackChunkName: "about" */ './components/About/About.vue'),
          meta: {
            title: 'О Helperia',
            canonical: '/p/about',
          },
        },
        {
          path: 'faq',
          name: 'about-faq',
          component: () => import(/* webpackChunkName: "about" */ './components/About/FAQ.vue'),
          meta: {
            title: 'FAQ',
            canonical: '/p/faq',
          },
        },
        {
          path: 'terms',
          name: 'about-terms',
          component: () => import(/* webpackChunkName: "about" */ './components/About/Terms.vue'),
          meta: {
            title: 'Пользовательское соглашение',
            canonical: '/p/terms',
          },
        },
        {
          path: 'privacy',
          name: 'about-privacy',
          component: () => import(/* webpackChunkName: "about" */ './components/About/Privacy.vue'),
          meta: {
            title: 'Политика конфиденциальности',
            canonical: '/p/privacy',
          },
        },
        {
          path: 'contacts',
          name: 'about-contacts',
          component: () => import(/* webpackChunkName: "about" */ './components/About/Contacts.vue'),
          meta: {
            title: 'Контакты',
            canonical: '/p/contacts',
          },
        },
      ],
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
      meta: {
        title: 'Создание канала',
        canonical: '/register',
      },
    },
    {
      path: '/register/activate/:key',
      name: 'activate-profile',
      component: () => import(/* webpackChunkName: "register" */ './views/ActivateProfile.vue'),
      props: route => ({
        key: route.params.key
      }),
      meta: {
        title: 'Активация профиля',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
      meta: {
        title: 'Сброс пароля',
        canonical: '/reset-password',
      },
    },
    {
      path: '/reset-password/token/:rtoken',
      name: 'reset-password-via-token',
      component: () => import(/* webpackChunkName: "change-password" */ './views/ChangePassword.vue'),
      props: route => ({
        resetToken: route.params.rtoken
      }),
      meta: {
        title: 'Смена пароля',
      },
    },
    {
      path: '/changelog',
      name: 'changelog',
      component: () => import(/* webpackChunkName: "changelog" */ './views/Changelog.vue'),
      meta: {
        title: 'Список изменений',
        canonical: '/changelog',
      },
    },
    {
      path: '/redirect-to-login-with/:authprovider',
      name: 'redirect-to-login-with',
      component: () => import(/* webpackChunkName: "third-party-auth" */ './views/RedirectToLoginWith.vue'),
      props: route => ({
        authProviderName: route.params.authprovider,
      }),
    },
    {
      path: '/auth-callback/:authprovider',
      name: 'auth-callback',
      component: () => import(/* webpackChunkName: "third-party-auth" */ './views/AuthCallback.vue'),
      props: route => ({
        authProviderName: route.params.authprovider
      }),
    },
    {
      path: '/admin/tag-manager',
      name: 'tag-manager',
      component: () => import(/* webpackChunkName: "admin" */ './views/TagManager.vue'),
      meta: {
        title: 'Менеджер тегов',
        canonical: '/admin/tag-manager',
      },
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import(/* webpackChunkName: "errors" */ './views/NotFound.vue'),
      meta: {
        title: 'Страница не найдена',
        canonical: '/404',
      },
    },
    {
      path: '/403',
      name: 'forbidden',
      component: () => import(/* webpackChunkName: "errors" */ './views/Forbidden.vue'),
      meta: {
        title: 'Доступ запрещён',
        canonical: '/403',
      },
    },
    {
      path: '*',
      redirect: {
        name: 'not-found',
      },
    },
  ]
})

<template>
  <div class="loading-block">
    <div class="wrap">
      <img src="../../assets/icons/loading.svg" alt="Загрузка">
      <span>Загрузка…</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBlock',
}
</script>

<style lang="less" scoped>
  .loading-block {
    display: flex;
    flex-grow: 1;
  }

  .wrap {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    animation: rotating 0.5s steps(12) infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg) scaleX(-1);
    }
    to {
      transform: rotate(360deg) scaleX(-1);
    }
  }

  span {
    font-size: 20pt;
    opacity: 0.5;
    margin-top: 0.5em;
  }
</style>

<template>
  <div class="user-actions">
    <router-link :to="{ name: 'about-about' }">О проекте</router-link>
    <router-link v-if="!$root['auth']" :to="{ name: 'register' }">Создать канал</router-link>
    <router-link
      v-if="$root['auth'] && $root['auth']['username'] !== null"
      :to="{ name: 'user-profile', params: { slug: $root['auth'].username } }"
    >{{ $root['auth'].username }}</router-link>
    <router-link
      v-if="$root['auth'] && $root['auth']['username'] === null"
      :to="{ name: 'change-username' }"
      class="emphasis"
    >Укажите имя</router-link>
    <a href="#" @click="showLogInOverlay" v-if="!$root['auth']">Вход</a>
    <a href="#" @click="logOut" v-if="$root['auth']">Выход</a>
  </div>
</template>

<script>
export default {
  name: 'UserActions',

  data() {
    return {
      isLoggingOut: false,
    }
  },

  methods: {
    showLogInOverlay(event) {
      event.preventDefault()
      this.$root.overlay = 'log-in'
    },

    logOut(event) {
      event.preventDefault()

      if(this.isLoggingOut) {
        return
      }

      this.isLoggingOut = true
      this.$root.makeAPIRequest('auth', 'log_out')
        .then((response) => {
          if(response.data.error) {
            console.warn(`Ошибка выхода: ${response.data.message}`)
          }

          this.$root.dropSession()
          this.isLoggingOut = false
        })
        .catch(() => {
          this.isLoggingOut = false
        })
    }
  },
}
</script>

<style lang="less" scoped>
  @import '../../shared.less';

  .user-actions {
    margin-left: 2em;

    &.is-mobile {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      align-items: flex-start;
    }
  }

  a {
    @padding: 1em;
    @mobile-border: 2px solid transparent;

    padding: 0 @padding;
    border-left: 1px solid @color-border;
    color: @color-text;

    .is-mobile & {
      border-left: @mobile-border;
      padding: 0.25em 1em;
      font-size: 13pt;
    }

    &:first-child {
      padding-left: 0;
      border-left: unset;

      .is-mobile & {
        padding-left: @padding;
        border-left: @mobile-border;
      }
    }

    &:last-child {
      padding-right: 0;

      .is-mobile & {
        padding-right: @padding;
      }
    }
  }
</style>

<template>
  <form class="log-in-form" @submit="formSubmitted" ref="form" target="passwordsaver">
    <iframe name="passwordsaver" id="passwordsaver" @load="iframeLoaded" />
    <span class="log-in">Вход</span>
    <span class="error" v-if="lastError">{{ lastError }}</span>
    <input type="email" placeholder="E-mail" size="100" autocomplete="username" v-model="email" @keyup.enter="focusPasswordField" />
    <input type="password" placeholder="Пароль" size="150" autocomplete="current-password" v-model="password" ref="passwordField" @keyup.enter="authenticate()"/>
    <input
      type="submit"
      class="button log-in-button"
      :class="{ enabled: (email.length > 0) && (password.length > 0) }"
      value="Войти"
    />
    <!--suppress HtmlUnknownTarget -->
    <a href="/reset-password" target="_blank" class="forgot-password">Забыли пароль?</a>
    <span class="or-separator">или с помощью соцсетей</span>
    <div class="social-logins">
      <!--suppress HtmlUnknownTarget -->
      <img src="/images/scl-icons/vk.svg" alt="VK" class="social-login vk" @click="loginWithAuthProvider('vk')">
    </div>
    <div class="overlay" v-if="isLoggingIn">
      <LoadingBlock/>
    </div>
  </form>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
export default {
  name: 'LogInForm',
  components: { LoadingBlock },
  data() {
    return {
      email: '',
      password: '',
      lastError: null,
      isLoggingIn: false,
    }
  },

  beforeDestroy() {
    window.removeEventListener('storage', this.thirdPartyAuthSucceeded)
  },

  methods: {
    focusPasswordField(event) {
      event.preventDefault()
      event.stopPropagation()

      if(this.email.length < 1) {
        return
      }

      const passwordField = this.$refs.passwordField
      passwordField.focus()
    },

    formSubmitted(event) {
      event.preventDefault()
      event.stopPropagation()

      if(this.email.length < 1 || this.password.length < 1) {
        return
      }

      if(this.$refs.form.submitted) {
        return
      }

      this.$refs.form.submitted = true

      this.authenticate()
    },

    authenticate() {
      if(this.isLoggingIn) {
        return
      }

      if(this.email.length < 1 || this.password.length < 1) {
        return
      }

      this.isLoggingIn = true

      this.$root.makeAPIRequest('auth', 'log_in', {
        login: this.email,
        password: this.password,
      })
        .then((response) => {
          if(response.data.error) {
            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            let lastError = null
            switch (response.data.handler) {
              case 'too-frequent-attempts':
                lastError = 'Слишком частые попытки входа. Подождите несколько секунд и попробуйте снова.'
                break
              case 'internal-server-error':
                lastError = 'Ошибка сервера. Попробуйте позже. Если проблема продолжается долгое время, обратитесь к администратору.'
                break
              case 'incorrect-credentials':
                lastError = 'Неверно введены данные для входа. Проверьте правильность ввода.'
                break
            }

            this.lastError = lastError
            this.isLoggingIn = false
            this.password = ''
            this.$refs.passwordField.focus()
            return
          }

          const auth = {
            token: response.data.result.token,
            username: response.data.result.username,
          }

          this.$root.setSession(auth)
          this.$refs.form.submitted = false
          this.$refs.form.submit()
        })
        .catch(() => {
          this.lastError = 'Проверьте соединение с интернет'
          this.isLoggingIn = false
        })
    },

    iframeLoaded() {
      if(!this.isLoggingIn) {
        return
      }

      this.isLoggingIn = false
      this.$root.overlay = null
    },

    loginWithAuthProvider(authProviderName) {
      localStorage.removeItem('authData')
      window.addEventListener('storage', this.thirdPartyAuthSucceeded)

      const url = this.$router.resolve({
        name: 'redirect-to-login-with',
        params: { authprovider: authProviderName }
      }).href
      window.open(url)
    },

    thirdPartyAuthSucceeded() {
      const authDataJSON = localStorage.getItem('authData')
      if(authDataJSON === null) {
        return
      }

      window.removeEventListener('storage', this.thirdPartyAuthSucceeded)
      localStorage.removeItem('authData')

      const authData = JSON.parse(authDataJSON)
      const auth = {
        token: authData.token,
        username: authData.username,
      }
      this.$root.setSession(auth)
      this.$root.overlay = null
    },
  },
}
</script>

<style lang="less" scoped>
  @import '../../shared.less';

  .log-in-form {
    display: flex;
    flex-direction: column;
    width: 270px;
    position: relative;
  }

  iframe {
    display: none;
  }

  .log-in {
    font-family: @font-set-header;
    font-size: @font-size-header;
    align-self: center;
    margin-bottom: 0.25em;
  }

  .error {
    background-color: @color-error;
    color: @color-block-background;
    padding: 0.5em 1em;
    font-size: 10pt;
    margin-bottom: 0.5em;
  }

  input {
    .input();
  }

  .log-in-button {
    background-color: @color-accent;
    padding: 0.5em;
    text-align: center;
    font-size: @font-size-normal;
    color: @color-block-background;
    margin-top: 0.5em;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;

    &.enabled {
      pointer-events: initial;
      opacity: initial;
    }
  }

  .overlay {
    .overlay();
    background-color: fadeout(@color-block-background, 20%);
  }

  .forgot-password {
    display: block;
    margin: 0.75em auto 0;
    font-size: @font-size-normal * 0.8;
  }

  .or-separator {
    align-self: center;
    text-align: center;
    opacity: 0.3;
    font-size: 0.8em;
    margin: 1.5em 0;
  }

  .social-logins {
    display: flex;
    justify-content: center;
  }

  .social-login {
    @size: 25px;
    width: @size;
    height: @size;
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.15s linear;

    &:hover {
      opacity: 0.7;
    }
  }
</style>

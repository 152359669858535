<template>
  <div class="scl-links">
    <a
      v-for="link in links"
      :key="link.icon"
      :title="link.title"
      :href="link.href"
      target="_blank"
      rel="noopener"
    >
      <!--suppress HtmlUnknownTarget -->
      <img :src="`/images/scl-icons/${link.icon}.svg`" :alt="link.title">
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
  data() {
    return {
      links: [
        { icon: 'vk', title: 'VK', href: 'https://vk.com/official_helperia' },
        { icon: 'fb', title: 'Facebook', href: 'https://facebook.com/helperia.ru' },
        { icon: 'ok', title: 'Одноклассники', href: 'https://ok.ru/helperia' },
        { icon: 'twr', title: 'Twitter', href: 'https://twitter.com/helperia_world' },
        { icon: 'telegram', title: 'Telegram', href: 'https://t.me/helperia' },
        { icon: 'yandex-zen', title: 'Яндекс Дзен', href: 'https://zen.yandex.ru/media/helperia' },
      ]
    }
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  a {
    .black-to-accent();
    display: inline-block;

    .greyscale & {
      filter: unset;
      opacity: 0.5;
    }

    &+& {
      margin-left: @s-margin / 2;

      @media @tablet-s {
        margin-left: @s-margin;
      }
    }
  }

  img {
    --size: 18px;
    width: var(--size);
    height: var(--size);

    @media @tablet-s {
      --size: 22px;
    }
  }
</style>

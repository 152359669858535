<template>
  <router-link class="logo" :to="{ name: 'home' }">
    <img class="background" src="../../assets/icons/logo-background.svg" alt="Логотип Helperia">
    <img class="text" src="../../assets/icons/logo-text.svg" alt="Логотип Helperia">
  </router-link>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  a {
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;

    &.is-mobile {
      height: var(--bar-height);
    }
  }

  .background {
    position: absolute;
    top: -6px;
    z-index: @z-index-logo-background;

    .is-mobile & {
      display: none;
    }
  }

  .text {
    z-index: @z-index-logo-text;
    margin-left: 32px;
    margin-top: 8px;

    .is-mobile & {
      margin-left: 16px;
      height: calc(var(--bar-height) * 2/3);
    }
  }
</style>

<template>
  <div class="format-nav">
    <router-link
      :to="{ name: 'users' }"
      class="type-user"
      :class="{ 'is-reading': $route.name === 'content-reader' && $route.params.type === 'u' }"
    >Каналы</router-link>
    <router-link
      :to="{ name: 'articles' }"
      class="type-article"
      :class="{ 'is-reading': $route.name === 'content-reader' && $route.params.type === 'a' }"
    >Статьи</router-link>
    <router-link
      :to="{ name: 'discussions' }"
      class="type-discussion"
      :class="{ 'is-reading': $route.name === 'content-reader' && $route.params.type === 'd' }"
    >Дискуссии</router-link>
    <router-link
      :to="{ name: 'events' }"
      class="type-event"
      :class="{ 'is-reading': $route.name === 'content-reader' && $route.params.type === 'e' }"
    >Публичные события</router-link>
    <!--<router-link
      :to="{ name: 'questions' }"
      class="type-question"
      :class="{ 'is-reading': $route.name === 'content-reader' && $route.params.type === 'q' }"
    >Вопросы</router-link>-->
  </div>
</template>

<script>
export default {
  name: 'FormatNav'
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .format-nav {
    display: flex;
    font-size: 13pt;
    font-weight: 700;

    &.is-mobile {
      font-weight: 400;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 5px
    }
  }

  a {
    @border: 2px solid var(--type-color);
    @vertical-margin: 0.25em;
    @horizontal-margin: @s-margin * (2/3);

    border-bottom: @border;
    padding: @vertical-margin 0;
    color: @color-text;
    margin: 0 @horizontal-margin 0 (@horizontal-margin + @s-margin);
    position: relative;
    transition: color 0.1s ease-in-out;
    z-index: @z-index-format-nav-a;

    &:first-child {
      margin-left: @horizontal-margin;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -(@horizontal-margin);
      width: calc(100% + (@horizontal-margin * 2));
      height: calc(100% + 3px);
      background: linear-gradient(to bottom, var(--type-color), var(--type-color)) repeat-x 0 100%;
      background-size: 1px 0;
      transition: background-size 0.1s ease-in-out;
      z-index: @z-index-format-nav-a-background;
    }

    &:hover,
    &.router-link-exact-active,
    &.is-reading {
      color: @color-block-background;

      &::after {
        background-size: 1px 100%;
      }
    }

    .is-mobile & {
      border-bottom: unset;
      border-left: @border;
      border-left-width: 10px;
      padding: 0 @horizontal-margin 0 5px;
      margin: @vertical-margin 0;

      &.router-link-exact-active,
      &.is-reading {
        padding: @vertical-margin @horizontal-margin @vertical-margin 5px;
        margin: 0;
        border-right: @border;

        &:hover {
          color: @color-block-background;

          &::after {
            background-size: 1px 100%;
          }
        }
      }

      &::after {
        width: 100%;
        height: 100%;
        left: 0;
      }

      &:hover {
        color: @color-text;

        &::after {
          background-size: 1px 0;
        }
      }
    }

    @media @tablet-s {
      display: none;
    }

    &.router-link-exact-active,
    &.is-reading,
    .is-mobile & {
      @media @tablet-s {
        display: block;
      }
    }
  }
</style>

<template>
  <div
    class="mobile-nav-toggler"
    :class="{ active: $root['isMobileMenuActive'] }"
    @click="$root['isMobileMenuActive'] = !$root['isMobileMenuActive']"
  >
    <span class="icon"/>
  </div>
</template>

<script>
export default {
  name: 'MobileNavToggler',
}
</script>

<style lang="less" scoped>
  @import '../../shared.less';

  .mobile-nav-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    width: var(--bar-height);
    height: var(--bar-height);
    padding: 0;
  }

  .icon {
    box-sizing: content-box;
    width: 36px;
    height: 20px;
    border-bottom: 4px solid @color-text;
    position: relative;
    transform-origin: 50% 100%;
    transition: transform 0.4s;

    .active & {
      transform: translate(1px, -9px) rotate(-45deg);
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: @color-text;
      -webkit-transition: inherit;
      transition: inherit;
    }

    &::before {
      top: 0;
      transform-origin: inherit;

      .active & {
        transform: translate(-2px, 18px)  rotate(90deg);
      }
    }

    &::after {
      top: 10px;
      transform-origin: 0 0;

      .active & {
        transform: translate(9px, 0) rotate(45deg) scale(0,1);
      }
    }
  }
</style>

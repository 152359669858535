<template>
  <div class="overlay" @click="$root.overlay = null">
    <div class="wrapper" @click="stopOverlayPropagation">
      <LogInForm v-if="$root['overlay'] === 'log-in'" />
    </div>
  </div>
</template>

<script>
import LogInForm from '@/components/Overlay/LogInForm'
export default {
  name: 'Overlay',
  components: { LogInForm },

  methods: {
    stopOverlayPropagation(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style lang="less" scoped>
  @import '../shared.less';

  .overlay {
    .overlay();
    position: fixed;
    background-color: hsla(0, 0%, 0%, 0.6);
    z-index: @z-index-global-overlay;
    display: flex;
  }

  .wrapper {
    margin: auto;
    background-color: @color-block-background;
    padding: @m-margin;
  }
</style>
